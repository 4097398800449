'use client';

import classNames from 'classnames';
import { useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useWindowSize } from 'react-use';

import styles from './styles/tooltip.module.scss';

type PropsTypes = {
  title: any;
  content: any;
  timeout?: number;
  forceClose?: boolean | 'mobile';
  tooltipClassName?: string;
  tooltipMessageClassName?: string;
  openCondition?: boolean;
};

const Tooltip = ({
  title,
  content,
  timeout = 500,
  forceClose = false,
  tooltipClassName = '',
  tooltipMessageClassName = '',
  openCondition = true,
}: PropsTypes) => {
  const [tooltip, setTooltip] = useState(false);
  const titleTimeout = useRef<any>(null);
  const tooltipTimeout = useRef<any>(null);
  const { width } = useWindowSize();
  const isMobile = width <= 726;

  const clearTimeouts = () => {
    clearTimeout(titleTimeout.current);
    clearTimeout(tooltipTimeout.current);
  };

  const titleClick = () => {
    setTooltip(true);
  };

  const titleMouseEnter = () => {
    clearTimeouts();
    setTooltip(true);
  };

  const titleMouseLeave = () => {
    titleTimeout.current = setTimeout(() => setTooltip(false), timeout);
  };

  const tooltipMouseEnter = async () => {
    await clearTimeouts();
    setTooltip(true);
  };

  const tooltipMouseLeave = () => {
    tooltipTimeout.current = setTimeout(() => setTooltip(false), timeout);
  };

  const onForceClose = (e) => {
    e.preventDefault();
    clearTimeouts();
    setTooltip(false);
  };

  const tooltipApperance = classNames('m-0', styles.tooltipBase, {
    [tooltipClassName]: tooltipClassName,
  });

  const tooltipMessageApperance = classNames(styles.tooltipMessage, {
    [tooltipMessageClassName]: tooltipMessageClassName,
  });

  return (
    <div
      className={tooltipApperance}
      onMouseEnter={() => openCondition && titleMouseEnter()}
      onMouseLeave={() => openCondition && titleMouseLeave()}
    >
      <div onClick={() => openCondition && titleClick()}>{title}</div>
      {tooltip && (
        <div
          className={tooltipMessageApperance}
          onMouseEnter={() => tooltipMouseEnter()}
          onMouseLeave={() => tooltipMouseLeave()}
        >
          {forceClose === true ||
            (isMobile && forceClose === 'mobile' && (
              <IconContext.Provider
                value={{ color: 'rgb(242, 136, 79)', size: '20px' }}
              >
                <div
                  className={styles.tooltipClose}
                  onClick={(e) => {
                    onForceClose(e);
                  }}
                >
                  <AiOutlineCloseCircle />
                </div>
              </IconContext.Provider>
            ))}
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
