import cn from 'classnames';
import { InputHTMLAttributes, forwardRef } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}
export default forwardRef<HTMLInputElement, InputProps>(function Input(
  { error, className, ...props },
  ref,
) {
  const disabledClasses = 'disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-600';
  const readonlyClasses = 'readonly:cursor-not-allowed readonly:bg-gray-200 readonly:text-gray-600';
  return (
    <input
      {...props}
      ref={ref}
      className={cn(
        'w-full rounded-lg border-2 border-gray-650 bg-white px-4 py-2 text-black outline-none placeholder:text-sm placeholder:text-gray-500 focus:border-premium-500',
        disabledClasses,
        readonlyClasses,
        { 'border-red-500': error },
        className,
      )}
    />
  );
});
