'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { useLocale } from 'next-intl';
import { useForm } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { z } from 'zod';

import { NewsletterSubscribePage } from './NewsletterSubscribe';

import { Input } from '@components/Form';
import { logger } from '@logger';
import { fetchApi } from '@utils/api/client';
import {
  IpressoFetchResponse,
  IpressoPostBody,
} from '@utils/api/server/ipresso';

const schema = z.object({
  email: z.string().email(),
});

interface NewsletterSubscribeFormLabels {
  email: string;
}
interface NewsletterSubscribeFormProps {
  className?: string;
  page: NewsletterSubscribePage;
  labels: NewsletterSubscribeFormLabels;
}
export default function NewsletterSubscribeForm({
  page,
  className,
  labels,
}: NewsletterSubscribeFormProps) {
  const locale = useLocale();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    const bodyObject: IpressoPostBody = {
      contactData: {
        email,
      },
      lang: {
        category: locale,
      },
      categoryData: {
        [page]: true,
      },
      agreementData: {
        newsletter: true,
      },
      source: page,
    };

    try {
      await fetchApi<IpressoFetchResponse>('/campaign/edit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObject),
      });
      reset();
    } catch (e) {
      logger.error(e);
      setError('email', {
        message: 'Something went wrong',
      });
    }
  });

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      className={cn('flex items-center', className)}
    >
      <Input
        placeholder={labels.email}
        className="focus:border-magazine-500 mr-2 max-w-64"
        error={!!errors.email}
        {...register('email')}
      />

      <button
        type="submit"
        className="bg-magazine-500 flex h-10 w-10 items-center justify-center rounded-lg p-2 disabled:opacity-60"
        disabled={isSubmitting}
      >
        {!isSubmitting ? (
          <IoPaperPlaneOutline className="h-8 w-8 text-white" />
        ) : (
          <CgSpinner className="animate-spin text-xl text-white" />
        )}
      </button>
    </form>
  );
}
