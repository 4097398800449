'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useLocale } from 'next-intl';
import { useForm } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { z } from 'zod';

import { Input, TextArea } from '@components/Form';
import { logger } from '@logger';
import { fetchApi } from '@utils/api/client';

const schema = z.object({
  name: z.string().min(3).max(255),
  email: z.string().email(),
  message: z.string().min(3),
  consent: z.boolean().optional(),
});

interface ContactFormFormLabels {
  name: string;
  email: string;
  message: string;
  submit: string;
}

interface ContactFormFormProps {
  className?: string;
  labels: ContactFormFormLabels;
}
export default function ContactFormForm({
  className,
  labels,
}: ContactFormFormProps) {
  const locale = useLocale();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (locale === 'de' && !data.consent) {
      setError('consent', {
        message: 'You must agree to the privacy policy',
      });
      return;
    }

    try {
      await fetchApi('/contact', {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          locale,
          source: 'magazine',
        }),
      });

      reset();
    } catch (e) {
      logger.error(e);
      setError('message', {
        message: 'Something went wrong, please try again later.',
      });
    }
  });

  return (
    <form className={className} onSubmit={onSubmit} noValidate>
      <div className="mb-2 lg:mb-4 lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="mb-2 lg:mb-0">
          <Input
            type="text"
            error={!!errors.name}
            placeholder={labels.name}
            {...register('name')}
          />
          {errors.name && (
            <p className="text-xs text-red-500">{errors.name.message}</p>
          )}
        </div>
        <div className="mb-2 lg:mb-0">
          <Input
            type="email"
            error={!!errors.email}
            placeholder={labels.email}
            {...register('email')}
          />
          {errors.email && (
            <p className="text-xs text-red-500">{errors.email.message}</p>
          )}
        </div>
      </div>
      <div className="mb-2">
        <TextArea
          placeholder={labels.message}
          rows={8}
          error={!!errors.message}
          {...register('message')}
        />
        {errors.message && (
          <p className="text-xs text-red-500">{errors.message.message}</p>
        )}
      </div>
      {/* {locale === 'de' && (
        <Checkbox
          label={t.rich('contact.policy', {
            aPolicy: (chunks) => (
              <Link
                className="text-magazine-500"
                href={t('contact.policyLink')}
                target="_blank"
              >
                {chunks}
              </Link>
            ),
          })}
          className="mb-2"
          error={!!errors.consent}
          {...register('consent')}
        />
      )} */}

      <button
        className="bg-magazine-500 flex w-full items-center justify-center rounded-lg px-4 py-2 text-white disabled:cursor-not-allowed disabled:opacity-60 lg:px-6 lg:py-3"
        disabled={isSubmitting}
      >
        {isSubmitting && <CgSpinner className="mr-1 animate-spin" />}
        {labels.submit}
      </button>
    </form>
  );
}
